import React from 'react';
import './CourseToggle.scss';
import LineText from './../../../Shared/Components/LineText/LineText';
import Button from './../../../Shared/Components/Button/Button';

const CourseToggle = (props) => {
    return (
        <div className="animated fadeInUp row course-toggle no-gutters" style={{ position: 'relative' }}>
            <div className="container-xxl">
                <div className="container-ac">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                        <LineText lineText="The detail" center={true} />
                        <h2>BESPOKE TRAINING SOLUTIONS</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseToggle;