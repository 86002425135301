import React from 'react';
import TextRow from './../../Shared/Components/TextRow/TextRow';
import LineIcon from 'react-lineicons';
import './Thankyou.scss';

const Thankyou = () => {
    return (
        <div className="thanks">
            <LineIcon name="checkmark" alt="Thank you" />
            <TextRow divClass="p-0" lineText="Thank you" title="We have your message" paragraph="We have your message and a member of our team will be intouch with you shortly" />
        </div>
    )
};

export default Thankyou;
