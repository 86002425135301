import React from 'react';
import './Hero.scss';
import HeroImage from '../../../Assets/Images/Home/hero.jpg';
import HeroMob from '../../../Assets/Images/Home/hero-mob.jpg';
import { isMobile } from 'react-device-detect';
import LineText from '../../Shared/Components/LineText/LineText';

const Hero = () => {
    return (
        <header className="row no-gutters hero shadow fadeInDelay animated">
            <div className="overlay"></div>
            <div className="hero-img p-5 " style={{
                backgroundImage: isMobile ? `url(${HeroMob})` : `url(${HeroImage
                    })`
            }}></div>
            <div className="container-xxl hero-text">
                <div className="block">
                    <h1>Your Training Specialists</h1>
                </div>
                <p>We work behind the scenes helping you, your teams and your business to succeed improving skills, knowledge and delivering transformational programmes to increase sales and customer experience</p>
            </div>
        </header>
    );
};

export default Hero;