import React from 'react';
import './LineText.scss';

const LineText = ({ lineText, center }) => {
    return (
        <>
            {lineText &&
                <div className={`${center ? "line-center" : "line-row"}`}>
                    <span>{lineText}</span>
                </div>
            }
        </>
    );
};

export default LineText;