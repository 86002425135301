import React from 'react';
import Button from './Components/Button/Button';

const NotFound = () => {
    return (
        <div className="row text-center not-found content">
            <div className="col-12 col-md-8 md-offset-2">
                <h1 className="mb-2">404</h1>
                <h2 className="mb-4">PAGE NOT FOUND</h2>
                <p>Sorry for the inconvenience. It looks like you are trying to access a page that has been removed or never even existed.</p>
                <p>Click below to go back to our homepage.</p>
                <div className="text-center pt-3">
                    <Button center={true} buttonText="Home" buttonUrl="/" />
                </div>
            </div>
        </div>
    );
};

export default NotFound;