import React from 'react';
import './PartnersRow.scss';
import { FadeInOnScroll } from '../../Shared/Utilities/FadeInOnScroll';
import LineText from './../../Shared/Components/LineText/LineText';
import Button from './../../Shared/Components/Button/Button';

const PartnersRow = ({ lineText, title, image, paragraph1, paragraph2, paragraph3, divClass, animate, rowReverse, buttonText, buttonUrl, children }) => {
    const [ref] = FadeInOnScroll();

    return (
        <section className={`row no-gutters partners ${divClass != null ? divClass : ""}`}>
            <div className="container-xxl">
                <div ref={animate ? ref : null} className="container-ac">
                    <div className={`partners-image-container no-gutters  ${rowReverse ? "row-reverse" : ""}`}>
                        <div className={`col-12 col-md-7 ${rowReverse ? "reverse" : "pr-0 pr-md-5"}`}>
                            <LineText lineText={lineText} />
                            <div className="text mb-4">
                                <h2>{title}</h2>
                                <div className="pt-2">
                                    <p>{paragraph1}</p>
                                    {paragraph2 && <p>{paragraph2}</p>}
                                    {paragraph3 && <p>{paragraph3}</p>}
                                </div>
                            </div>
                            {children}
                            {buttonText && buttonUrl &&
                                <div className="text-center text-md-left pt-3">
                                    <Button buttonText={buttonText} buttonUrl={buttonUrl} />
                                </div>
                            }
                        </div>
                        <div className={`col-12 col-md-5 text-center image`}>
                            <img src={image} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default PartnersRow;