import React from 'react';
import { FadeInOnScroll } from '../../Shared/Utilities/FadeInOnScroll';
import LineIcon from 'react-lineicons';

const ServiceItem = ({ icon, title, description }) => {
    const [ref] = FadeInOnScroll();

    return (
        <div ref={ref} className="icon-text">
            <LineIcon name={icon} alt="Your Business" />
            <div className="text">
                <h5>{title}</h5>
                <hr />
                <p>{description}</p>
            </div>
        </div>
    );
};

export default ServiceItem;