import React from 'react';
import Marquee from "react-fast-marquee";
import Logo2 from '../../../../Assets/Images/OurPartners/savilles.jpg';
import Logo3 from '../../../../Assets/Images/OurPartners/hereeast.png';
import Logo4 from '../../../../Assets/Images/OurPartners/Heathrow.png';
import Logo5 from '../../../../Assets/Images/OurPartners/Worlddutyfree.png';
import Logo6 from '../../../../Assets/Images/OurPartners/BLS.png';
import Logo7 from '../../../../Assets/Images/OurPartners/abeuk.jpg';
import Logo8 from '../../../../Assets/Images/OurPartners/WhatIf.png';
import './ScrollingBanner.scss';
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';

const ScrollingBanner = ({ lineText, title, text, align, bgColor }) => {
    const [ref] = FadeInOnScroll();

    const logos = [
        {
            Logo: Logo2
        },
        {
            Logo: Logo3
        },
        {
            Logo: Logo4
        },
        {
            Logo: Logo5
        },
        {
            Logo: Logo6
        },
        {
            Logo: Logo7
        },
        {
            Logo: Logo8
        },
        {
            Logo: Logo2
        },
        {
            Logo: Logo3
        },
        {
            Logo: Logo4
        },
        {
            Logo: Logo5
        },
        {
            Logo: Logo6
        },
        {
            Logo: Logo7
        },
        {
            Logo: Logo8
        }
    ];

    return (
        <>
            <Marquee
                pauseOnHover={true}
                speed="35"
                gradient={true}
                gradientWidth="100"
            >
                <div ref={ref} className="py-3 scroll-container" style={{ backgroundColor: `${bgColor ? bgColor : ""}` }}>
                    <div className="col-12 scroll-banner">
                        {logos.map((l) => (
                            <img className="img-fluid" src={l.Logo} alt="" />
                        ))}
                    </div>
                </div>

            </Marquee>
        </>

    );
};

export default ScrollingBanner;
