import React from 'react';
import './Course.scss';
import LineIcon from 'react-lineicons';
import { FadeInOnScroll } from '../../../Shared/Utilities/FadeInOnScroll';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Course = ({ title, paragraph, courseLength, img, overlay, pdf }) => {
    const [ref] = FadeInOnScroll();

    return (
        <div ref={ref} className="course-item">
            {overlay &&
                <div className="overlay-desktop">
                    <img src={overlay} width="200px" height="200px" className="" alt="" />
                </div>
            }
            <img src={img} className="img-fluid" alt="" width="480px" height="370px" />
            <div className="row no-gutters course-info" style={{width:'100%'}}>
                {overlay &&
                    <div className="overlay-mobile">
                        <img src={overlay} width="200px" height="200px" className="" alt="" />
                    </div>
                }
                <div className="info-container">
                    <h5>{title}</h5>
                    <p>{paragraph}</p>
                    {pdf &&
                    <p className='pt-2'><Link to={pdf} target='blank'>Click here to read more</Link></p>
                    }
                    <div className="d-flex justify-content-end mt-auto">
                        <div className="text-right course-length">
                            <LineIcon name="timer" alt="Your Business" style={{ color: "#f38621", marginRight: "10px" }} />
                            <span className="pull-right">{courseLength}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Course;