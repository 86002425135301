import React from 'react';
import LineText from './../LineText/LineText';
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';

const TextRow = ({ title, lineText, paragraph, animate, divClass, children }) => {
    const [ref] = FadeInOnScroll();

    return (
        <section ref={animate ? ref : null} className={`row no-gutters row-pad ${divClass ? divClass : ""}`}>
            <div className="container-xxl">
                <div className="container-ac">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                        <LineText lineText={lineText} center={true} />
                        <h2>{title}</h2>
                        <p className="mt-3">{paragraph}</p>
                    </div>
                    {children}
                </div>
            </div>
        </section>
    );
};

export default TextRow;