import React from 'react';
import LineIcon from 'react-lineicons';

const ContactMethodItem = ({ title, paragraph, icon, phone }) => {
    return (
        <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0 method-item">
            <LineIcon name={icon} alt="Your Business" />
            <h5>{title}</h5>
            <p>{paragraph}</p>
            {phone &&
                <a href={`tel: ${phone}`}>
                    {phone}
                </a>
            }
        </div>
    );
};

export default ContactMethodItem;