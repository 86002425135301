import React from 'react';
import Hero from '../../Assets/Images/document.jpg';
import HeroMob from '../../Assets/Images/documentMob.jpg';
import Header from './../Shared/Components/Header/Header';

const TermsAndConditions = () => {
    return (
        <>
            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="The important stuff"
                title="Terms &amp; Conditions"
                subtitle="" />

            <div className="container-ac container-xxl py-5 legal-docs">
                <p>February 2021</p>
                <h5 className="pb-3">By Using Our Site You Accept These Terms and Conditions</h5>
                <p><strong>Please read these Terms and Conditions carefully and ensure that you understand them before using Our Site</strong>.</p>
                <p>These Terms and Conditions, together with any other documents referred to herein, set out the terms of use governing your use of this website www.academyone.co.uk(“Our Site”). It is recommended that you print a copy of these Terms and Conditions for your future reference.</p>
                <p>These Terms and Conditions were last updated on 15<sup>th</sup> February 2021.</p>
                <p>Your agreement to comply with these Terms and Conditions is indicated by your use of Our Site. If you do not agree to these Terms and Conditions, you must stop using Our Site immediately.</p>
                <p>The following document<strong>[</strong>s<strong>]</strong> also apply to your use of Our Site:</p>
                <ul>
                    <li>Our Privacy Policy, available at <a href="/privacy-policy">www.academyone.co.uk/privacy-policy</a>. This is also referred to below in Part 15.</li>
                </ul>
                <ol>
                    <li><h5>Definitions and Interpretation</h5>
                        <ul>
                            <li>In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</li>
                        </ul>
                    </li>
                </ol>
                <p style={{ paddingLeft: "60px" }}> <strong>“Content”</strong></p>
                <p style={{ paddingLeft: "90px" }}>means any and all text, images, audio, video, scripts, code, software, databases, and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Site; and</p>
                <p style={{ paddingLeft: "60px" }}><strong>“We/Us/Our”</strong></p>
                <p style={{ paddingLeft: "90px" }}>means academyONELtd</p>
                <ol start="2">
                    <li><h5>Information About Us</h5>
                        <ul>
                            <li>Our Site is operated by academyONE. <strong>[</strong>We are a limited company registered in England and Wales under company number09301259. Our registered address is Chesil Queens Park Road, Chester, CH47AD.</li>
                            <li><strong>[</strong>Our VAT number is 288107384</li>
                        </ul>
                    </li>
                </ol>
                <ol start="3">
                    <li><h5>How to Contact Us</h5></li>
                </ol>
                <p>To contact us, please email us at <a href="mailto:hello@academyone.co.uk">hello@academyone.co.uk</a></p>
                <ol start="4">
                    <li><h5>Access to Our Site</h5>
                        <ul>
                            <li>Access to Our Site is free of charge.</li>
                            <li>It is your responsibility to make the arrangements necessary in order to access Our Site.</li>
                            <li>Access to Our Site is provided on an “as is” and on an “as available” basis. We may suspend or discontinue Our Site (or any part of it) at any time. We do not guarantee that Our Site will always be available or that access to it will be uninterrupted. <strong>[</strong>If We suspend or discontinue Our Site (or any part of it), We will try to give you reasonable notice of the suspension or discontinuation.<strong>]</strong></li>
                        </ul>
                    </li>
                </ol>

                <ol start="5">
                    <li><h5>Changes to Our Site</h5></li>
                </ol>
                <p>We may alter and update Our Site (or any part of it) at any time <strong>to update services and update information</strong>. <strong>[</strong>If We make any <strong>[</strong>significant<strong>]</strong> alterations to Our Site (or any part of it), We will try to give you reasonable notice of the alterations.<strong>]</strong></p>

                <ol start="6">
                    <li><h5>Changes to these Terms and Conditions</h5>
                        <ul>
                            <li>We may alter these Terms and Conditions at any time. If We do so, details of the changes will be highlighted at the top of this page. As explained above, your use of Our Site constitutes your acceptance of these Terms and Conditions. Consequently, any changes made to these Terms and Conditions will apply to your use of Our Site the first time you use it after the changes have been implemented. You are therefore advised to check this page every time you use Our Site.</li>
                            <li>If any part of the current version of these Terms and Conditions conflicts with any previous version(s), the current version shall prevail unless We explicitly state otherwise.</li>
                        </ul>
                    </li>
                </ol>

                <ol start="7">
                    <li><h5>International Users</h5></li>
                </ol>
                <p>Our Site is intended for users in the United Kingdom only. We do not warrant or represent that Our Site or its Content are available in other locations or are suitable for use in other locations.<strong>]</strong></p>

                <ol start="8">
                    <li><h5>How You May Use Our Site and Content (Intellectual Property Rights)</h5>
                        <ul>
                            <li><strong>[</strong>All Content included on Our Site and the copyright and other intellectual property rights in that Content belongs to or has been licensed by Us, unless specifically labelled otherwise. All Content is protected by applicable United Kingdom and international intellectual property laws and treaties.</li>
                            <li>You may access, view, and use Our Site in a web browser (including any web browsing capability built into other types of software or app) and you may download Our Site (or any part of it) for caching (this usually occurs automatically).</li>
                            <li>You may print one copy and download extracts of any page(s) from Our Site for personal use only.</li>
                            <li>You may not modify the printed copies or downloaded extracts in any way. Images, video, audio, or any other Content downloaded from Our Site must not be used separately from accompanying text.</li>
                            <li>Our status as the owner and author of the Content on Our Site (or that of identified licensors, as applicable) must always be acknowledged.</li>
                            <li>You may not use any Content <strong>[</strong>saved or downloaded<strong>]</strong> from Our Site for commercial purposes without first obtaining a licence from Us (or our licensors, as applicable). <strong>[</strong>This does not prevent the normal access, viewing, and use of Our Site for general information purposes by business users or consumers.<strong>]]</strong></li>
                        </ul>
                    </li>
                </ol>
                <p><strong>OR</strong></p>
                <ul>
                    <li><strong>[</strong>All Content included on Our Site and the copyright and other intellectual property rights in that Content belongs to or has been licensed by Us, unless specifically labelled otherwise. All Content is protected by applicable United Kingdom and international intellectual property laws and treaties.</li>
                    <li>You may access, view, and use Our Site in a web browser (including any web browsing capability built into other types of software or app) and you may download Our Site (or any part of it) for caching (this usually occurs automatically).</li>
                    <li>You may print copies and download extracts of any page(s) from Our Site <strong>[</strong>for personal use<strong>]</strong>.</li>
                    <li><strong>[</strong>Our status as the owner and author of the Content on Our Site (or that of identified licensors, as applicable) must always be acknowledged.<strong>]</strong></li>
                    <li><strong>[</strong>You may not use any Content <strong>[</strong>saved or downloaded<strong>]</strong> from Our Site for commercial purposes without first obtaining a licence from Us (or Our licensors, as applicable). <strong>[</strong>This does not prevent the normal access, viewing, and use of Our Site for general information purposes by business users or consumers.<strong>]]</strong></li>
                    <li><strong>[</strong>Nothing in these Terms and Conditions limits or excludes the provisions of Chapter III of the Copyright, Designs and Patents Act 1988, ‘Acts Permitted in Relation to Copyright Works’, which provides exceptions allowing certain uses of copyright material including (but not limited to) non-commercial research and private study; text and data mining for non-commercial research; criticism, review, and reporting current events; teaching; accessibility; time-shifting; and parody, caricature, and pastiche. Further information is available from the UK <a href="https://www.gov.uk/guidance/exceptions-to-copyright">Intellectual Property Office</a>.<strong>]]</strong></li>
                </ul>

                <ol start="9">
                    <li><h5>Links to Our Site</h5>
                        <ul>
                            <li><strong>[</strong>You may link to any page on Our Site<strong>] OR [</strong>You may only link to the homepage of Our Site, <a href="http://www.academyone.co.uk">academyone.co.uk</a> Linking to other pages on Our Site requires our express written permission.<strong>]</strong></li>
                            <li>Links to Our Site must be fair and lawful. You must not take unfair advantage of Our reputation or attempt to damage Our reputation.</li>
                            <li>You must not link to Our Site in a manner that suggests any association with Us (where there is none) or any endorsement or approval from Us (where there is none).</li>
                            <li>Your link should not use any logos or trade marks displayed on Our Site without Our express written permission.</li>
                            <li><strong>[</strong>You must not frame or embed Our Site on another website without Our express written permission.<strong>]</strong></li>
                            <li><strong>[</strong>You may not link to Our Site from another website the main content of which is unlawful; obscene; offensive; inappropriate; dishonest; defamatory; threatening; racist, sexist, or otherwise discriminatory; that promotes violence, racial hatred, or terrorism; that infringes intellectual property rights; or that We deem to be otherwise objectionable.<strong>]</strong></li>
                        </ul>
                    </li>
                </ol>

                <ol start="10">
                    <li><h5>Links to Other Sites</h5>
                        <ul>
                            <li>Links to other websites may be included on Our Site. Unless expressly stated, these sites are not under Our control. We accept no responsibility or liability for the content of third-party websites.</li>
                            <li>The inclusion of a link to another website on Our Site is for information purposes only and does not imply any endorsement of that website or of its owners, operators, or any other parties involved with it.</li>
                        </ul>
                    </li>
                </ol>

                <ol start="11">
                    <li><h5>Disclaimers</h5>
                        <ul>
                            <li>Nothing on Our Site constitutes professional advice on which you should rely. It is provided for general information purposes only. <strong>[</strong>Professional or specialist advice should always be obtained before taking any action relating to training and development.</li>
                            <li>We make reasonable efforts to ensure that the Content on Our Site is complete, accurate, and up to date, but We make no warranties, representations, or guarantees (express or implied) that this will always be the case.</li>
                            <li>If you are a business user, We exclude all implied representations, warranties, conditions, and other terms that may apply to Our Site and Content.</li>
                        </ul>
                    </li>
                </ol>

                <ol start="12">
                    <li><h5>Our Liability</h5>
                        <ul>
                            <li>Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be lawfully excluded or restricted.</li>
                            <li>If you are a business user (i.e. you are using Our Site in the course of business or for commercial purposes), to the fullest extent permissible by law, We accept no liability for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our Site or the use of or reliance upon any Content included on Our Site.</li>
                            <li>If you are a business user, We accept no liability for loss of profit, sales, business, or revenue; loss of business opportunity, goodwill, or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.</li>
                            <li><strong>[</strong>Our Site is intended for non-commercial use only.<strong>]</strong> If you are a consumer, you agree that <strong>[</strong>you will not use Our Site for any commercial or business purposes and that<strong>]</strong> We shall have no liability to you for any business losses as set out above.</li>
                            <li><strong>[</strong>Subject to Part 12.6, if<strong>] OR [</strong>If<strong>]</strong> you are a consumer and digital content from Our Site damages other digital content or a device belonging to you, where that damage is caused by Our failure to use reasonable skill and care, We will either compensate you or repair the damage.</li>
                            <li><strong>[</strong>Note that the right to compensation or repair in Part 12.5 will be lost if the damage in question could have been avoided by following advice or instructions from Us to install a free patch or update; if the damage resulted from your failure to follow instructions; or if the minimum system requirements provided by Us for the digital content in question were not met.<strong>]</strong></li>
                        </ul>
                    </li>
                </ol>

                <ol start="13">
                    <li><h5>Viruses, Malware, and Security</h5>
                        <ul>
                            <li>We exercise reasonable skill and care to ensure that Our Site is secure and free from viruses and malware; however, We do not guarantee that this is the case.</li>
                            <li>You are responsible for protecting your hardware, software, data, and other material from viruses, malware, and other internet security risks.</li>
                            <li>You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Site.</li>
                            <li>You must not attempt to gain unauthorised access to any part of Our Site, the server on which Our Site is stored, or any other server, computer, or database connected to Our Site.</li>
                            <li>You must not attack Our Site by means of a denial of service attack, a distributed denial of service attack, or by any other means.</li>
                            <li>By breaching the provisions of Parts 13.3 to 13.5, you may be committing a criminal offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our Site will cease immediately in the event of such a breach.</li>
                        </ul>
                    </li>
                </ol>

                <ol start="14">
                    <li><h5>Acceptable Usage of Our Site</h5>
                        <ul>
                            <li>You may only use Our Site in a lawful manner:
                                <ol>
                                    <li>You must ensure that you comply fully with any and all local, national, or international laws and regulations that apply;</li>
                                    <li>You must not use Our site in any way, or for any purpose, that is unlawful or fraudulent; and</li>
                                    <li>You must not use Our Site to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware or any other code designed to adversely affect computer hardware, software, or data of any kind.</li>
                                </ol>
                            </li>
                            <li>If you fail to comply with the provisions of this Part 14, you will be in breach of these Terms and Conditions. We may take one or more of the following actions in response:
                                <ol>
                                    <li>Suspend or terminate your right to use Our Site;</li>
                                    <li>Issue you with a written warning;</li>
                                    <li>Take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;</li>
                                    <li>Take further legal action against you, as appropriate;</li>
                                    <li>Disclose such information to law enforcement authorities as required or as We deem reasonably necessary; and/or</li>
                                    <li>Any other actions which We deem reasonably appropriate (and lawful).</li>
                                </ol>
                            </li>
                            <li>We hereby exclude any and all liability arising out of any actions that We may take (including, but not limited to those set out above in Part 14.2) in response to your breach.</li>
                        </ul>
                    </li>
                </ol>

                <ol start="15">
                    <li><h5>How We Use Your Personal Information</h5></li>
                </ol>
                <p>We will only use your personal information as set out in Our Privacy Policy, available <a href="/privacy-policy">here</a> .</p>

                <ol start="16">
                    <li><h5>Communications from Us</h5>
                        <ul>
                            <li>If We have your contact details, We may send you important notices by email from time to time. Such notices may relate to matters including, but not limited to, changes to Our Site or to these Terms and Conditions.</li>
                            <li>We will not send you marketing emails without your express consent. If you do consent to marketing, you may opt out at any time. All marketing emails from Us include an unsubscribe link. If you opt out of emails from Us, it may take up to 5 days for your request to take effect and you may continue to receive emails during that time.</li>
                            <li>For questions or complaints about communications from Us, please contact Us using the details above in Part 3.<strong>]</strong></li>
                        </ul>
                    </li>
                </ol>

                <ol start="17">
                    <li><h5>Law and Jurisdiction</h5>
                        <ul>
                            <li>These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with, English law.</li>
                            <li>If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in Part 17.1 takes away from or reduces your legal rights as a consumer.</li>
                            <li>If you are a consumer, any dispute, controversy, proceedings, or claim between you and Us relating to these Terms and Conditions or to the relationship between you and Us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency.</li>
                            <li>If you are a business user, any dispute, controversy, proceedings, or claim between you and Us relating to these Terms and Conditions or to the relationship between you and Us (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of England and Wales.</li>
                        </ul>
                    </li>
                </ol>
            </div>
        </>
    );
};

export default TermsAndConditions;