import React from 'react';

const CourseStats = ({ countShowing, count }) => {
    return (
        <div className="col-12 mt-3 course-stats">
            <div className="text-right">
                <p>Showing {countShowing} of {count} courses</p>
            </div>
        </div>
    );
};

export default CourseStats;