import React from 'react';
import './CourseBox.scss';
import LineIcon from 'react-lineicons';
import { FadeInOnScroll } from '../../../Shared/Utilities/FadeInOnScroll';

const CourseBox = ({ title, paragraph, length, img }) => {
    const [ref] = FadeInOnScroll();
    return (
        <>
            <div ref={ref} className=" col-12 col-md-4">
                <div className="featured-course">
                    <img src={img} className="img-fluid" alt="" />
                    <div className="row no-gutters featured-course-info">
                        <div className="featured-info">
                            <h5>{title}</h5>
                            <p className="truncate-overflow">{paragraph}</p>
                            <div className="text-right pt-2 course-length">
                                <LineIcon name="timer" alt="Your Business" style={{ color: "#f38621", marginRight: "10px" }} />
                                <span className="pull-right">{length} mins</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CourseBox;