import React from 'react';
import './Button.scss';
import { Link } from 'react-router-dom';

const Button = ({ buttonText, buttonUrl, divClass, center, click, outline }) => {
    return (
        <>
            {buttonUrl ?
                <Link to={buttonUrl} className={`${outline ? "ac-btn" : "ac-btn-full"} ${divClass ? divClass : ""}`} style={{ margin: center ? "auto" : "0" }} role="button">
                    <div className="">
                        {buttonText}
                    </div>
                </Link>
                :
                <div className={`${outline ? "ac-btn" : "ac-btn-full"} ${divClass ? divClass : ""}`} style={{ margin: center ? "auto" : "0" }} onClick={click}>
                    <div className="">
                        {buttonText}
                    </div>
                </div>
            }
        </>
    );
};

export default Button;