import React from 'react';
import Header from '../Shared/Components/Header/Header';
import './About.scss';
import Hero from '../../Assets/Images/About/hands.jpg';
import HeroMob from '../../Assets/Images/About/hands-mob.jpg';
import TextImageRow from '../Shared/Components/TextImageRow/TextImageRow';
import Shop from '../../Assets/Images/About/shop.jpg';
import Handshake from '../../Assets/Images/About/handshake.jpg';
import Mall from '../../Assets/Images/About/mall.jpg';
import ScrollingBanner from '../Shared/Components/ScrollingBanner/ScrollingBanner';
import VideoRow from '../Shared/Components/VideoRow/VideoRow';
import academyOne from '../../Assets/Video/academyOne.mp4';

const About = () => {
    return (
        <>
            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Our business"
                title="About Us"
                subtitle="At academyONE, we 'Love to Learn'"
            />
            <div className="about-container">
                <VideoRow
                    title="Welcome To academyONE"
                    paragraph1="We are a professional team of creative, passionate and ethical people who all love the art of imparting knowledge to collectively aid individual and business growth. We too ‘love to learn’."
                    paragraph2="Enough reading, why don't you have a look at the video animation below and see if we can help."
                    lineText="Our Video Animation"
                    video={academyOne}
                />

                <TextImageRow
                    lineText="Client Relationships"
                    title="Who Are We?"
                    paragraph1="Founded in 2011, by Grosvenor Liverpool ONE shopping centre and purchased in 2012 by Sara Wilcock, we continue to work with some of the UK’s largest shopping centre developments and destinations to design and implement training solutions."
                    paragraph2="academyONE was specifically created to support the needs of retail and visitor destinations and ensure centre staff and their occupiers have the skills to give every visitor an excellent customer experience."
                    image={Shop}
                    rowReverse={false}
                    animate={true}
                />

                <TextImageRow
                    lineText="Who Are We?"
                    title="11 Years On"
                    paragraph1="academyONE has evolved and today we are delivering solutions to help your team successfully manage your destinations and understand the concepts of placemaking and increasing sales and footfall."
                    paragraph2="Our clients range from property management companies across retail, hospitality, visitor experience, airport, managed white spaces and facilities management operators."
                    image={Mall}
                    rowReverse={true}
                    animate={true}
                />

                <TextImageRow
                    lineText="Who Are We?"
                    title="Our Journey So Far"
                    paragraph1="To date we have created and delivered thousands of training interventions across the UK and we are proud that we continue to support our clients to achieve their customer excellence strategies, improve sales, develop management teams, consider the environment  we work within and introduce a sense of community and place to your destinations whilst embedding sustainable practices."
                    paragraph2="We work with you to create and design the training, provide experienced delivery professionals and deliver the training at your site or online to make the experience as seamless as possible."
                    image={Handshake}
                    rowReverse={false}
                    animate={true}
                />

                <ScrollingBanner
                    lineText="Our Clients"
                    title="Partners"
                    align="center"
                    bgColor="#f7f7f7"
                />
            </div>
        </>
    );
};

export default About;