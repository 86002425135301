import React from 'react';
import './GetInTouch.scss';
import GetInTouchImage from '../../../../Assets/Images/GetInTouch/get-in-touch.jpg'
import ImageRow from './../ImageRow/ImageRow';
import Button from '../Button/Button';

const GetInTouch = () => {
    return (
        <ImageRow
            image={GetInTouchImage}
            imageMob={GetInTouchImage}
            divClass="get-in-touch">
            <div className="container-xxl container-ac get-in-touch-text">
                <div className="col-12 col-md-10 offset-md-1">
                    <h2 className="text-center">Get In Touch</h2>
                    <hr style={{ borderTop: "2px solid white", textAlign: "center", margin: "auto", paddingTop: "2rem" }} />
                    <p className="text-center">We are proud to work with many brand names to support the training architecture of their organisations, give us a call and if we can help, we will, if not, we will know someone who can.</p>
                    <div className="text-center pt-3 mx-auto">
                        <Button outline={false} center={true} buttonText="Contact Us" buttonUrl="/contact-us" />
                    </div>
                </div>
            </div>
        </ImageRow >
    );
};

export default GetInTouch;