import React, { useState } from 'react';
import CourseToggle from '../CourseToggle/CourseToggle';
import GoalList from './../GoalList/GoalList';
import CourseList from '../CourseList/CourseList';

const Programmes = ({ divClass }) => {
    const [showCourses, setShowCourses] = useState(false);

    const toggleCourses = () => {
        setShowCourses(!showCourses);
    }

    return (
        <>
            <CourseToggle divClass="bgGrey" />
            <div className="" style={{ minHeight: "100vh" }}>
                <CourseList />
            </div>
        </>
    );
};

export default Programmes;