import React from 'react';
import './ImageRow.scss';
import { isMobile } from "react-device-detect";
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';

const ImageRow = ({ imageMob, imgOpacity, image, divClass, children, animate }) => {
    const [ref] = FadeInOnScroll();

    return (
        <div ref={animate ? ref : null} className={`row no-gutters image-row shadow ${divClass ? divClass : ""}`}>
            <div className="section-img" style={{ backgroundImage: isMobile ? `url(${imageMob})` : `url(${image})`, opacity: `${imgOpacity ? imgOpacity : '1'}` }}>
                <div className="container-ac container-xxl center-content">
                    <div className="col-12">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageRow;