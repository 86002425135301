import React from 'react';
import './Services.scss';
import ServiceItem from './ServiceItem';

const Services = ({ items }) => {
    return (
        <section className={`row no-gutters services`}>
            <div className="container-xxl container-ac">
                <div className={`services-container`}>
                    <div className="text wrap mb-4 col-12">
                        {items.map((i) => (
                            <ServiceItem
                                icon={i.Icon}
                                title={i.Title}
                                description={i.Description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;