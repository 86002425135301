import React, { useEffect, useState } from 'react';
import './CourseList.scss';
import Goal1 from '../../../../Assets/Images/Training/Courses/unconcious-bias.jpg';
import UnconsciousBias from '../../../../Assets/Images/Training/Courses/unconcious-bias.jpg';
import ModernSlavery from '../../../../Assets/Images/Training/Courses/modern-slavery.jpg';
import CustomerServiceExcellence from '../../../../Assets/Images/Training/Courses/Customer-service-excellence.jpg';
import MaintainingTheStandard from '../../../../Assets/Images/Training/Courses/maintaining-the-standard.jpg';
import DiverseCustomers from '../../../../Assets/Images/Training/Courses/supporting-diverse-customers.jpg';
import Equality from '../../../../Assets/Images/Training/Courses/equality-and-diversity.jpg';
import Factory from '../../../../Assets/Images/Training/Courses/factory.jpg';
import Courses from '../Courses/Courses';
import Button from './../../../Shared/Components/Button/Button';
import CourseStats from '../CourseStats/CourseStats';
import Merch from '../../../../Assets/Images/Training/Courses/merch.jpg'
import Customer from '../../../../Assets/Images/Training/Courses/customer.jpg'
import Skills from '../../../../Assets/Images/Training/Courses/skills.jpg'
import Aquisition from '../../../../Assets/Images/Training/Courses/aquisition.jpg'
import Sales from '../../../../Assets/Images/Training/Courses/sales-up.jpg'
import Reward from '../../../../Assets/Images/Training/Courses/reward.jpg'
import Communication from '../../../../Assets/Images/Training/Courses/communication.jpg'
import Time from '../../../../Assets/Images/Training/Courses/manage-time.jpg'
import Chess from '../../../../Assets/Images/Training/Courses/chess.jpg'
import Appraisal from '../../../../Assets/Images/Training/Courses/appraisal.jpg'
import Teams from '../../../../Assets/Images/Training/Courses/teams.jpg'
import Change from '../../../../Assets/Images/Training/Courses/change.jpg'
import Process from '../../../../Assets/Images/Training/Courses/process.jpg'
import Staff from '../../../../Assets/Images/Training/Courses/staff.jpg'
import VisualMerchFlyer from '../../../../Assets/pdfs/VisualMerchandise-Flyer.pdf'
import RetailSaleFlyer from '../../../../Assets/pdfs/RetailSale-Flyer.pdf'

const courses = [
    {
        Title: "Equality & Diversity",
        Description: "This programme will support your colleague population in understanding the importance of equality and diversity and how we can all support our organisations and colleagues to embrace a more diverse world where everyone is equal. This is an excellent programme to run as part of mandatory induction or refresher.",
        CourseLength: "60 mins",
        Image: Equality,
        Category: "Course"
    },
    {
        Title: "Unconscious Bias",
        Description: "This programme is designed to deliver knowledge and understanding to help your colleague population to control and manage unconscious bias. It explains the different types of unconscious bias, and how it can manifest and present itself in our everyday lives, both in work and personally. Colleagues will learn to recognise when it occurs and gain the knowledge and skills to enable them to overcome their bias. This programme is an excellent addition to company induction or as a refresher for colleagues and is fully mapped to the 17 Sustainable Development Goals.",
        CourseLength: "60 mins",
        Image: UnconsciousBias,
        Category: "Course"
    },
    {
        Title: "Modern Slavery",
        Description: "This programme is designed to enable your colleague population to understand the current challenge of modern slavery, the origins of slavery, concept of modern slavery and applicable law to protect human rights. This programme is an excellent addition to company induction or as a refresher and is fully mapped to the 17 Sustainable Development Goals",
        CourseLength: "60 mins",
        Image: ModernSlavery,
        Category: "Course"
    },
    {
        Title: "Placemaking",
        Description: "This programme adopts the principles of place first economics which have been mapped to the 17 UN Sustainable Development Goals. This course demonstrates the importance of recognising the place we live and work and how together with your business is part of a thriving community that supports one common goal. An excellent course for asset and property management organisations and also local and regional councils to demonstrate how Place Management is a key factor towards supporting the achievement of the 17 SDGs.",
        CourseLength: "Full Day",
        Image: Factory,
        Category: "Course"
    },
    {
        Title: "Customer Service Excellence",
        Description: "This programme is designed to take your colleague population through a full customer service journey exploring effective communication, first impressions and how to handle challenging situations. A fun interactive day delivered by our team of customer service experts.",
        CourseLength: "60 mins",
        Image: CustomerServiceExcellence,
        Category: "Course"
    },
    {
        Title: "Maintaining the Standard (Customer Service Excellence refresher)",
        Description: "We are all guilty of becoming complacent and this programme is a refresher designed to ensure the principles of your organisations customer service strategy are maintained.",
        CourseLength: "60 mins",
        Image: MaintainingTheStandard,
        Category: "Course"
    },
    {
        Title: "Considering Needs - Discrimination Awareness in a Service Industry",
        Description: "This programme will support your colleague population to recognise and celebrate the different needs of both customers and colleagues. Encourage a better understanding of the concepts of equality and diversity and ensure we are treating ALL of our customers and colleagues fairly and with dignity.",
        CourseLength: "60 mins",
        Image: DiverseCustomers,
        Category: "Course"
    },
    {
        Title: "Sales Training: Improving sales through visual merchandising",
        Description: "A one day programme bringing the theatre back into your store and helping to create that retail theatre and increase sales.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Merch,
        Pdf: VisualMerchFlyer
    
    },
    {
        Title: "Sales Training: Understanding yourself and the customer",
        Description: "Provide participants with a strong foundation in the principles of professional business-to-business sales. Introduce Customer Centric Selling and how it improves results and supports long-term loyalty. Overview of behaviour profiling and how this can be used for self and customer profiling to develop long-term customer relationships.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Customer,
        Pdf: RetailSaleFlyer
    },
    {
        Title: "Sales Training: Fundamental Sales Activities",
        Description: "Provide participants with the core skills to engage in professional and successful prospecting and associated customer acquisition activities.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Aquisition

    },
    {
        Title: "Sales Training: Creating the Sales Advantage",
        Description: "Provide participants with the skills, techniques, and knowledge necessary to identify and respond effectively to customer requirements and areas of hidden or unknown value that provide an opportunity for differentiation.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Skills

    },
    {
        Title: "Sales Training: Asking for the Business",
        Description: "Provide participants with the skills, techniques and knowledge necessary to deliver a compelling proposition in a way that clearly highlights the value to the customer of adopting the recommended solution in order to generate successful sales.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Sales
    },
    {
        Title: "Leadership & Management Training: Communicating Clearly",
        Description: "Learn how to convey your message confidently by improving your skills in every form of communication.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Communication

    },
    {
        Title: "Leadership & Management Training: Managing Time",
        Description: "Create space in your day by identifying time-wasting traps and planning your time realistically and effectively.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Time
    },
    {
        Title: "Leadership & Management Training: Making Decisions",
        Description: "Improve your managerial technique by discovering how to make productive, informed decisions.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Chess
    },
    {
        Title: "Leadership & Management Training: Delegating Successfully",
        Description: "Free your time, establish trust, and develop staff relationships by learning delegating techniques.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Staff
    },
    {
        Title: "Leadership & Management Training: Motivating People",
        Description: "Bring out the best in people by encouraging individual initiatives and rewarding achievement.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Reward
    },
    {
        Title: "Leadership & Management Training: Managing Teams",
        Description: "Maximize team standards by building up an outstanding team, releasing creativity, and achieving targets.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Teams
    },
    {
        Title: "Leadership & Management Training: Managing Change",
        Description: "Discover how to adapt to new situations by anticipating critical management skill.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Change
    },
    {
        Title: "Leadership & Management Training: Appraising Staff",
        Description: "Enhance staff performance by learning to prepare, manage and follow up appraisal interviews.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Appraisal
    },
    {
        Title: "Process Management workshop",
        Description: "A workshop designed to assist in the planning, development and management of organisational process through the understanding and use of Process Mapping methodologies. This can be for a specific team, department, or project to identify areas of weakness in process operations and design more efficient working practices, to enable teams to improve organisational processes and be empowered to identify future improvement opportunities.",
        CourseLength: "1 day",
        Category: "Course",
        Image: Process
    },
]
let coursesArray = [];

const CourseList = ({ divClass }) => {
    const [coursesToShow, setCoursesToShow] = useState([]);

    const [coursesPerPage] = useState(10);
    const [next, setNext] = useState(10);
    const [numberOfProgrammes, setNumberOfProgrammes] = useState();

    useEffect(() => {
        coursesArray = [];
        setCoursesToShow([])
        loopCoursesWithSlice(0, coursesPerPage);
    }, []);

    const loopCoursesWithSlice = (start, end) => {
        const slicedCourses = courses.slice(start, end);
        coursesArray = [...coursesArray, ...slicedCourses];

        setNumberOfProgrammes(courses.length);
        setCoursesToShow(coursesArray);
    };

    const handleLoadMoreCourses = () => {
        loopCoursesWithSlice(next, next + coursesPerPage);
        setNext(next + coursesPerPage);
    };

    return (
        <>
            <section className={`row no-gutters py-5 animated fadeInDelay h-100 ${divClass ? divClass : ""}`}>
                <div className="container-xxl">
                    <div className="container-ac">
                        <div className="col-12 text-center text-md-left pb-3">
                            <h2 className="mb-3">Our Programmes</h2>
                            <p><strong>All programmes can be delivered in either a traditional training environment with professional trainers, or using our online offer ensuring accessibility and the safety of all candidates.</strong></p>
                        </div>
                        <CourseStats countShowing={coursesArray.length} count={numberOfProgrammes} />
                        <Courses coursesToRender={coursesToShow} />
                        <CourseStats countShowing={coursesArray.length} count={numberOfProgrammes} />
                        {coursesToShow < courses &&
                            <Button buttonText="Load More" center={true} click={handleLoadMoreCourses} />
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default CourseList;