import React from 'react';
import './Testimonials.scss';
import Savills from '../../../../Assets/Images/OurPartners/savills1.jpg';
import WorldDutyFree from '../../../../Assets/Images/OurPartners/Worlddutyfree1.png';
import HereEast from '../../../../Assets/Images/OurPartners/hereeast1.png';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const TestimonialArray = [
    {
        Name: "Sara Carthy",
        Role: "Estate Director",
        Testimonial: "Sara and her team have a wealth of transferrable knowledge from industry, as well as a true passion for learning and development.  They are intuitive and ask the right questions, which allows them to quickly adapt and understand your vision, strategy and operational requirements. From the insight they glean, they work with you or for you, to successfully utilise their knowledge and develop bespoke training solutions and packages to support your business. They have a refreshing outlook, always sharing best practice and the latest innovations and most importantly they are easy to work with, which is not always the case in my experience.",
        Company: "HereEast",
        Image: HereEast
    },
    {
        Name: "Alison Tollerfield",
        Role: "Strategic Learning & Development Manager",
        Testimonial: "The training team at academyONE have been delivering our customer service strategy across our centres in the UK for over 5 years and have facilitated the training operating under our Savills brand.They continue to work with The Strategic Learning and Development Manager for Savills Management Resources, to develop further products aligned to our overarching customer excellence strategy. Their attention to detail and flexibility in both design and delivery have made them an ideal partner to work with in the development of our learning and development programmes.",
        Company: "Savills Pathways",
        Image: Savills
    },
    {
        Name: "Collette Dixon",
        Role: "Store Manager",
        Testimonial: "The training academyONE designed has been a key objective within our growth strategy and we are delighted in the way that Sara and team have worked right from the outset with us with what I would describe again as a total ‘Can Do’ attitude, supporting us as a business to make this happen.",
        Company: "World Duty Free",
        Image: WorldDutyFree
    },
];

const Testimonials = () => {
    return (
        <div className="row">
            <AutoplaySlider
                infinite={true}
                mobileTouch={true}
                buttons={true}
            >
                {
                    TestimonialArray.map((t, i) => (
                        <div key={i} className="row no-gutters ac-testimonials">
                            <div className="col-12 text-center ac-content">
                                <div className="col-12 text-center">
                                    <img className="testimonial-image" src={t.Image} alt="" />
                                </div>
                                <h5 className="m-2">{t.Name}</h5>
                                <h6>{t.Company}</h6>
                                <p className="card-text">{t.Testimonial}</p>
                                <p className="role">{t.Role}</p>
                            </div>
                        </div>
                    ))
                }
            </AutoplaySlider>
        </div >
    );
};

export default Testimonials;