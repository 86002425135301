import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../Assets/academy-one-logo-no background.png';
import './Navbar.scss';
import { Fade as Hamburger } from 'hamburger-react'

const Navbar = (props) => {
    return (
        <nav className={`navbar navbar-expand-lg bg-white navbar-light fixed-top shadow-sm`}>
            <div className="container-xxl d-flex justify-content-between">
                <Link className="navbar-brand" to="/">
                    <img alt="Logo" width="400px" height="160px" id="brand-logo" className="img-fluid" src={Logo} /></Link>
                <div className="d-lg-none d-flex align-items-center">
                    <Hamburger rounded toggled={props.mobMenu} toggle={() => props.toggleMenu()} />
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <NavLink exact={true} className="nav-link" to="/">Home</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/about">About</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/training-and-development">Training &amp; Development</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/schools">Schools</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/consultancy">Consultancy</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/our-partners">Our Partners</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/contact-us">Contact</NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={`https://elearning.${window.location.host.replace("www.", "")}`}><strong>Login</strong></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;