import React from 'react';
import Row from './../../Shared/Components/Row/Row';
import LineText from './../../Shared/Components/LineText/LineText';
import WomanFade from '../../../Assets/Images/Home/woman-fade.jpg';
import WomanFadeMob from '../../../Assets/Images/Home/woman-fade-mob.jpg';

const Services = () => {
    return (
        <Row
            divClass="bgWhite"
            image={WomanFade}
            imageMob={WomanFadeMob}
            animate={true}>
            <div className="text-center text-md-left mx-2">
                <LineText lineText="Our Services" />
                <h2>Our Services</h2>
                <p>academyONE  has developed a range of education and training products under its Sustainability Academy brand which align to the United Nations (UN) 17 Sustainable Development Goals (SDGs) and are aimed at four key markets:</p>
            </div>
            <div className="home-service-wrapper">
                <div className="home-services text-center text-md-left pt-3">
                    <div className="">
                        <h6>KidsSDGs</h6>
                        <hr />
                    </div>
                    <div className="">
                        <h6>SDG's For Organisations</h6>
                        <hr />
                    </div>
                    <div className="">
                        <h6>SDG's For Start-ups and Entrepreneurs</h6>
                        <hr />
                    </div>
                    <div className="">
                        <h6>SDG Employability Programme</h6>
                        <hr />
                    </div>
                </div>
            </div>
        </Row>
    );
};

export default Services;