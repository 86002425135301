import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/academy-one-rev.png';
import LineIcon from 'react-lineicons';

const DATE = new Date();

const Footer = () => {
    return (
        <footer className="container-fluid">
            <div className="row footer py-1">
                <div className="container-ac">
                    <div className="row mt-4 mb-2">
                        <div className="brand col-lg-3 col-md-12 col-sm-12 mb-3">
                            <div className="flogo">
                                <img src={Logo} alt="logo" width="400px" height="120px" className="my-2 img-fluid footer-logo" />
                            </div>
                        </div>
                        <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12" >
                            <div className="finfo">
                                <div className="mb-1">
                                    <LineIcon name="twitter" alt="Your Business" style={{ color: "white", marginRight: '15px', fontSize: '1rem', display: 'inline-block' }} />
                                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/academyone_uk" style={{ display: 'inline-block' }}>
                                        @academyone_uk
                                    </a>
                                </div>
                                <div className="mb-1">
                                    <LineIcon name="phone" alt="Your Business" style={{ color: "white", marginRight: '15px', fontSize: '1rem', display: 'inline-block' }} />
                                    <a href="tel:02033939579" style={{ display: 'inline-block' }}>
                                        0203 393 9579
                                    </a>
                                </div>
                                <div className="mb-1">
                                    <LineIcon name="envelope" alt="Your Business" style={{ color: "white", marginRight: '15px', fontSize: '1rem', display: 'inline-block' }} />
                                    <a href="mailto:info@academyone.co.uk" style={{ display: 'inline-block' }}>
                                        info@academyone.co.uk
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12" >
                            <div className="finfo">
                                <h2>About</h2>
                                <Link className="footer-link" to="/contact-us">Contact Us</Link>
                                <Link className="footer-link" to="/terms-and-conditions">Terms & Conditions</Link>
                                <Link className="footer-link" to="/privacy-policy">Privacy Policy</Link>
                            </div>
                        </div>
                        <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12" >
                            <div className="finfo">
                                <h2>Registered office</h2>
                                <p>Academy One</p>
                                <p>Queens Park Road</p>
                                <p>Queens Park</p>
                                <p>Chester</p>
                                <p>CH4 7AD</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row footer-bottom">
                <div className="col-12 pt-3 text-center">
                    <p>&copy; AcademyOne {DATE.getFullYear()}</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;