import React from 'react';
import Hero from '../../Assets/Images/Consultancy/laptop-pencil.jpg';
import HeroMob from '../../Assets/Images/Consultancy/laptop-pencil-mob.jpg';
import Header from '../Shared/Components/Header/Header';
import Services from './Components/Services';
import TextRow from './../Shared/Components/TextRow/TextRow';
import GetInTouch from '../Shared/Components/GetInTouch/GetInTouch';
import ScrollingBanner from './../Shared/Components/ScrollingBanner/ScrollingBanner';

const ServiceItems = [
    {
        Icon: "consulting",
        Title: "Bespoke Training Packages",
        Description: "academyONE works with a range of affiliated partners who help us to create specialist training packages to suit your organisation such as leadership and management and digital technologies."
    },
    {
        Icon: "layers",
        Title: "Bespoke Sustainability Support",
        Description: "We have a range of professionals and speakers who can provide support for your business and help to create bespoke sustainability training packages aligned to your sustainability strategy."
    },
    {
        Icon: "grow",
        Title: "Counselling & Mindfulness",
        Description: "We have affiliated practitioners who can provide this specialist service."
    },
    {
        Icon: "stats-up",
        Title: "Environmental Social & Governance Support",
        Description: "Support to enhance your Environmental Social and Governance rating."
    },
    {
        Icon: "customer",
        Title: "Coaching & Mentoring",
        Description: "We are very proud to be able to recommend best in class coaching and mentoring services."
    },
    {
        Icon: "video",
        Title: "Animation",
        Description: "There is no better way to promote your business than by storytelling we are delighted to be able to offer this service."
    },

];

const Consultancy = () => {
    return (
        <>
            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="affiliated services"
                title="Consultancy"
                subtitle="For when you need that short term specialist service" />

            <TextRow
                lineText="Consultancy"
                title="Our Consultancy Team"
                paragraph="academyONE is proud to work with a range of professional organisations and individuals who can help with the following services"
                divClass="pb-0" />

            <Services items={ServiceItems} />

            <GetInTouch />

            <ScrollingBanner
                lineText="Our Clients"
                title="Partners"
                text=""
                align="center"
            />
        </>
    );
};

export default Consultancy;