import React from 'react';
import LineText from './../LineText/LineText';
import './VideoRow.scss';

const VideoRow = ({ title, paragraph1, paragraph2, lineText, video, img, imageHeight, divClass }) => {
    return (
        <article className={`row no-gutters video-container ${divClass}`}>
            <div className="container-xxl">
                <section className="container-ac video-section">
                    <div className="col-12 col-md-8 offset-md-2 text-center" style={{ marginBottom: "3rem" }}>
                        {img &&
                            <img src={img} className="text-center d-flex mb-4" alt="" style={{ height: imageHeight ? imageHeight : "150px", margin: "auto" }} />
                        }
                        <LineText lineText={lineText} center={true} />
                        <h2>{title}</h2>
                        <p className="mt-3">{paragraph1}</p>
                        <p className="mt-3">{paragraph2}</p>
                    </div>
                    <div className="float">
                        <div className="video-wrapper" >
                            <video className="video fadeInDelay animated"
                                src={video}
                                controls
                                playsInline
                                type='video/mp4'
                                muted
                            />
                        </div>
                    </div>
                </section>
            </div>
        </article >
    );
};

export default VideoRow;