import React from 'react';
import './Row.scss'
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';

const Row = ({ divClass, imageClass, children, animate }) => {
    const [ref] = FadeInOnScroll();

    return (
        <section ref={animate ? ref : null} className={`row no-gutters features-container ${divClass ? divClass : ""}`}>
            <div className="container-ac container-xxl">
                <div className="feature-col">
                    <div className="features">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Row;