import React from 'react';
import Hero from '../../Assets/Images/Training/Class.jpg'
import HeroMob from '../../Assets/Images/Training/Class-mob.jpg'
import Header from '../Shared/Components/Header/Header';
import CourseIntro from '../Shared/Components/TextColumns/TextColumns';
import Programmes from './Components/Programmes/Programmes';

const BespokeTraining = () => {
    return (
        <>
            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Our solutions"
                title="Training &amp; Development"
                subtitle="Helping your business to grow" />

            <CourseIntro
                lineText="People focussed"
                divClass="bgGrey"
                title="We take business training to the next level, with your people in mind"
                col1a="Our range of courses is ever evolving and we have developed our offer around the historic and new needs of our clients and the ever changing world we live in."
                col1b="Introducing a range of programmes we believe will support your teams through these challenging times whilst keeping your business compliant."
                col2a="We aim to help your team to build resilience, support mental health and wellbeing, support each other and understand the importance of equality, diversity and customer experience. All this whilst gaining valuable knowledge about how they can support themselves, your business and our world to be a more sustainable, meaningful and effective place."
            />

            <Programmes />
        </>
    );
};

export default BespokeTraining;