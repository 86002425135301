import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import Layout from './Layout/Layout/Layout';
import { createBrowserHistory } from 'history';
import About from './Pages/About/About';
import Consultancy from './Pages/Consultancy/Consultancy';
import OurPartners from './Pages/OurPartners/OurPartners';
import BespokeTraining from './Pages/BespokeTraining/BespokeTraining';
import NotFound from './Pages/Shared/NotFound';
import Home from './Pages/Home/Home';
import ScrollTop from './Pages/Shared/Utilities/ScrollTop';
import ContactUs from './Pages/ContactUs/ContactUs';
import Schools from './Pages/Schools/Schools';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';

const history = createBrowserHistory();

function App() {
  return (
    <React.Fragment>
      <Router history={history}>
        <ScrollTop>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/training-and-development" component={BespokeTraining} />
              <Route exact path="/schools" component={Schools} />
              <Route exact path="/consultancy" component={Consultancy} />
              <Route exact path="/our-partners" component={OurPartners} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </ScrollTop>
      </Router>
    </React.Fragment>
  );
}

export default App;
