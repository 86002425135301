import React from 'react';
import './WhyUs.scss';
import LineIcon from 'react-lineicons';

const WhyUs = () => {
    return (
        <div className="animated fadeInUpDelay row why-us-container no-gutters shadow" style={{ position: 'relative' }}>
            <div className="why-us mx-sm--auto">
                <div className="icon-box">
                    <div className="icon-circle">
                        <LineIcon name="apartment" alt="Your Business" style={{ color: "white" }} />
                    </div>
                    <div className="why-text">
                        Your<br /><strong>Business</strong>
                    </div>
                </div>
                <div className="icon-box">
                    <div className="icon-circle">
                        <LineIcon name="users" alt="Your Business" style={{ color: "white" }} />
                    </div>
                    <div className="why-text">
                        Your<br /><strong>People</strong>
                    </div>
                </div>
                <div className="icon-box">
                    <div className="icon-circle">
                        <LineIcon name="emoji-smile" alt="Your Business" style={{ color: "white" }} />
                    </div>
                    <div className="why-text">
                        Your<br /><strong>Ideals</strong>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyUs;