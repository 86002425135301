import React from 'react';
import Header from './../Shared/Components/Header/Header';
import Hero from '../../Assets/Images/ContactUs/contact.jpg';
import HeroMob from '../../Assets/Images/ContactUs/contact.jpg';
import ContactForm from './Components/ContactForm';
import TextRow from './../Shared/Components/TextRow/TextRow';
import ContactMethods from './Components/ContactMethods';

const ContactUs = () => {
    return (
        <>
            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Hello"
                title="Contact Us"
                subtitle="Let's see if we can help" />

            <TextRow
                title="Get In Touch"
                lineText="We are here to help"
                paragraph="We are proud to work with many brand names to support the training architecture of their organisations, give us a call and if we can help, we will, if not, we will know someone who can."
            >
                <ContactMethods />
                <ContactForm />
            </TextRow>
        </>
    );
};

export default ContactUs;