import React from 'react';
import TextRow from './../../Shared/Components/TextRow/TextRow';
import CourseBox from './CourseBox/CourseBox';
import Goal1 from '../../../Assets/Images/Training/Courses/sdg-intro.jpg'
import Goal2 from '../../../Assets/Images/Training/Courses/img-goal-2.jpg';
import Goal7 from '../../../Assets/Images/Training/Courses/img-goal-7.jpg';
import Button from '../../Shared/Components/Button/Button';
import { FadeInOnScroll } from '../../Shared/Utilities/FadeInOnScroll'
import Sustainability from '../../../Assets/Images/sustainability-logo.jpg';
import LineText from './../../Shared/Components/LineText/LineText';

const FeaturedCourses = () => {
    const [ref] = FadeInOnScroll();

    return (
        <section className={`row no-gutters row-pad `}>
            <div className="container-xxl">
                <div className="container-ac">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                        <img src={Sustainability} className="text-center d-flex mb-4" alt="" style={{ height: "150px", margin: "auto" }} />
                        <LineText lineText="Coming soon" center={true} />
                        <h2>Our Featured Programmes</h2>
                        <p className="mt-3">academyONE are proud to announce the launch of our sister academy The Sustainability Academy, a fully digitalised range of programmes to educate our colleague population and our communities on the meaning of sustainability and what WE can all do to have a positive impact on OUR planet.</p>
                        <p>Take a peek and see what you think...</p>
                    </div>
                    <div className="row mt-5 pt-4">
                        <CourseBox img={Goal1}
                            title="Sustainable Development Goals"
                            paragraph="This course will provide an overview and insight for individuals to understand what the 17 United Nations Sustainable Development Goals are, who is involved, what the global commitment is and how this translates to organisations and individual responsibility."
                            length="60" />
                        <CourseBox img={Goal2} title="Zero Hunger"
                            paragraph="This programme will provide a full overview into the global challenge to end hunger, achieve food security and improved nutrition and promote sustainable agriculture and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 2."
                            length="60" />
                        <CourseBox img={Goal7} title="Affordable &amp; Clean Energy"
                            paragraph="This programme will provide a full overview into the global challenge to ensure access to affordable, reliable, sustainable and modern energy for all and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 7"
                            length="60" />
                        <div ref={ref} className="col text-center pt-5">
                            <Button divClass="text-center" center={true} buttonText="Browse All Courses" buttonUrl="/training-and-development" />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default FeaturedCourses;