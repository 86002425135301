import React from 'react';
import Hero from './Components/Hero';
import WhyUs from './Components/WhyUs';
import './Home.scss';
import TextImageRow from '../Shared/Components/TextImageRow/TextImageRow';
import Board from '../../Assets/Images/Home/women-board.jpg';
import Clients from '../../Assets/Images/Home/people-handshake.jpg';
import ClientsMob from '../../Assets/Images/Home/people-handshake-mob.jpg';
import ImageRow from '../Shared/Components/ImageRow/ImageRow';
import TextRow from '../Shared/Components/TextRow/TextRow';
import Testimonials from '../Shared/Components/Testimonials/Testimonials';
import ScrollingBanner from './../Shared/Components/ScrollingBanner/ScrollingBanner';
import RowText from '../Shared/Components/RowText/RowText';
import FeaturedCourses from './Components/FeaturedCourses';
import Services from './Components/Services';

const Home = () => {
    return (
        <>
            <Hero />
            <WhyUs />
            <TextImageRow
                lineText="Committed To Success"
                title="Bespoke Training Solutions"
                paragraph1="We endeavour to understand what you are looking to achieve and together we devise supportive, bespoke and specialised training interventions that work. We are proud to create training solutions that support our clients to realise their ambitions."
                image={Board}
                divClass="intro"
                buttonText="About Us"
                buttonUrl="/About"
                animate={true}
            />

            <FeaturedCourses />

            <Services />

            <TextRow
                lineText="Our Online Platform"
                title="Online Training"
                paragraph="We've changed too, academyONE has invested in recreating our offer to enable our programmes to be delivered online. We have a host of engaging online content and a learner management system helping you to manage your training function. Our platform has been developed to enable you to use as a branded interface for your training."
                divClass="bgGrey"
                animate={true}
            />

            <ImageRow
                image={Clients}
                imageMob={ClientsMob}>
                <RowText lineText="Testimonials" title="What Our Clients Say" divClass="testimonial-text" />
                <Testimonials />
            </ImageRow>

            <ScrollingBanner
                lineText="Our Clients"
                title="Partners"
                text=""
                align="center"
            />
        </>
    );
};

export default Home;