import React from 'react';
import Header from '../Shared/Components/Header/Header';
import Hero from '../../Assets/Images/OurPartners/hug-circle.jpg';
import HeroMob from '../../Assets/Images/OurPartners/hug-circle-mob.jpg';
import './OurPartners.scss';
import SavillsLogo from '../../Assets/Images/OurPartners/savilles.jpg';
import SchoolLogo from '../../Assets/Images/OurPartners/school-logo.png';
import Abeuk from '../../Assets/Images/OurPartners/abeuk.jpg';
import Whatif from '../../Assets/Images/OurPartners/WhatIf.png';
import BLS from '../../Assets/Images/OurPartners/BLS.png';
import Handshake from '../../Assets/Images/OurPartners/handshake.jpg';
import ImageRow from './../Shared/Components/ImageRow/ImageRow';
import RowText from './../Shared/Components/RowText/RowText';
import PartnersRow from './Components/PartnersRow';
import PartnersIntro from './../Shared/Components/TextRow/TextRow';

const OurPartners = () => {
    return (
        <>
            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Our Affiliations"
                title="Our Partners"
                subtitle="We all need a little help from our friends" />

            <PartnersIntro
                lineText="AcademyONE"
                title="Our Affiliate Partners"
                paragraph="Over the past 10 years academyONE have been fortunate to work with some amazing and inspirational people who share our passion for inspiring others to grow and learn. We also realise we can't do everything and we like to stick to what we know best and call on our affiliates for their expertise ensuring we really can offer that one stop shop."
                divClass=""
            />

            <PartnersRow
                lineText="ABEUK"
                title="ABEUK.com"
                paragraph1="ABE is a not-for-profit skills development specialist and awarding organisation providing internationally recognised learning, credentials and quality assurance.  With our network of international partners across four continents, we develop skills that empower individuals, reduce gender inequality, and tackle extreme poverty. Since we started in 1973, around 1.5-million people globally have gained an ABE professional business qualification."
                paragraph2="Our programmes for schools give children the foundation for a better future by developing entrepreneurial know-how from a young age, whilst through our international network of accredited TVET centres, we enable people to become professionally qualified in business management, frequently providing a pathway to university education for people to whom it would have been inaccessible. "
                image={Abeuk}
                divClass="no-border-img"
                rowReverse={false}
                animate={true}
            />

            <PartnersRow
                lineText="Whatif..."
                title="Real Estate Differently"
                paragraph1="Whatif... we focus on the possibilities and opportunities rather than dwelling on the problems!"
                paragraph2="Our purpose is to offer a “balanced” view for the public, private and third sectors– to help create sustainable opportunities, assets and developments. We are particularly focused on a future for town centres that is vibrant, classless, diverse and inclusive. Passionate about the towns and communities where we invest time and/or money -we aim to make a real difference to place, people and business. We support the UN Sustainable Development Goals."
                image={Whatif}
                divClass="no-border-img partners"
                animate={true}
                rowReverse={true}
            />

            <PartnersRow
                lineText="Savills Pathways"
                title="Savills Pathways"
                paragraph1="Savills Pathways have been working with academyONE for over 5  years, and have procured their services to support the training team in the design and development of a suite of solutions to help Savills Pathways deliver a Customer Excellence strategy."
                paragraph2="The team at academyONE have designed a suite of Savills branded products that are now delivered across a portfolio of Shopping centres and serviced properties, ensuring shopping centre teams and front of house concierge personnel meet the standards of service delivery expected of Savills."
                image={SavillsLogo}
                divClass="no-border-img partners"
                rowReverse={false}
                animate={true}
            />

            <ImageRow
                image={Handshake}
                imageMob={Handshake}
                animate={true}>
                <RowText
                    lineText="Collaboration"
                    title="Growing together"
                    divClass="partners-text"
                />
                <div className="quote">
                    <div className="col-12 quote-text">
                        <p>
                            We are always growing our partnerships and our story is ever evolving. If you think you or your organisation can help us to provide a service to our clients throughout the next 10 years, then we'd love to talk to you.
                        </p>
                    </div>
                </div>
            </ImageRow>

            <PartnersRow
                lineText="Bishop Wilson C of E"
                title="Bishop Wilson C of E Primary School"
                paragraph1='"We were delighted that academyONE joined our school bank and shop project branded under Bishops Bank and Mere Stores, that represents the school and our local area where we are based."'
                image={SchoolLogo}
                divClass="no-box-shadow"
                animate={true}
                rowReverse={true}
            />

            <PartnersRow
                lineText="BLS"
                title="Business Learning Solutions"
                paragraph1="Business Learning Solutions is an online management training provider committed to offering quality and value to our customers."
                paragraph2="Our focus on personal development has enabled us to adapt world-class and tailored online training programmes to meet individual needs and enhance your performance. As a result, we have been successful in delivering training to people with diverse experience and capabilities from CEO’s to sales assistants. A snapshot of their clients include, British Airways, Dubai Ports Authority, Egyptian General Petroleum Company, Lloyds of London, Qatar Central Bank, Siemans,Shell, and many more."
                image={BLS}
                divClass="no-box-shadow"
                animate={true}
            />
        </>
    );
};

export default OurPartners;