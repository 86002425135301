import React from 'react';
import LineText from '../LineText/LineText';
import './TextColumns.scss';

const TextColumns = ({ lineText, title, divClass, col1a, col1b, col2a, col2b, center, animated, children }) => {

    return (
        <section className={`row no-gutters pb-5 courses ${animated ? "fadeInUpDelayFast animated" : ""} ${divClass ? divClass : ""}`}>
            <div className="container-xxl">
                <div className="container-ac">
                    {title && lineText &&
                        <div className={`${center ? "col-12 col-md-8 offset-md-2 pb-5 text-center" : "col-12 col-md-6 px-md-0 pb-3 text-center text-md-left"}`}>
                            <LineText lineText={lineText} center={center} />
                            <h2>{title}</h2>
                        </div>
                    }
                    <div className="row px-3 px-md-0 text-center text-md-left">
                        <div className="col-12 col-md-6">
                            <p>{col1a}</p>
                            <p>{col1b}</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <p>{col2a}</p>
                            <p>{col2b}</p>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </section>
    );
};

export default TextColumns;