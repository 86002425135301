import { useEffect, useRef } from 'react';

const FadeInOnScroll = (options) => {
    let ref = useRef();

    let appearOptions = {
        threshold: 0.7,
    }

    if (options) {
        appearOptions = options;
    }

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            entry.target.classList.add('fade-in');

            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add('appear');
            }
        }, appearOptions);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        }
    }, [ref, appearOptions]);

    return [ref];
}

export { FadeInOnScroll }; 