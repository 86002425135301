import React from 'react';
import Hero from '../../Assets/Images/Schools/schools.jpg';
import HeroMob from '../../Assets/Images/Schools/schools-mob.jpg';
import Header from '../Shared/Components/Header/Header';
import Classroom from '../../Assets/Images/Schools/classroom.jpg';
import Students from '../../Assets/Images/Schools/students.jpg';
import Children from '../../Assets/Images/Schools/children.jpg';
import Boy from '../../Assets/Images/Schools/boy-africa.jpg';
import Cosmo from '../../Assets/Images/Schools/cosmo.jpg';
import TextImageRow from '../Shared/Components/TextImageRow/TextImageRow';
import Sustainability from '../../Assets/Video/sdg-video.mp4';
import VideoRow from '../Shared/Components/VideoRow/VideoRow';
import './Schools.scss';

const Schools = () => {
    return (
        <>
            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="The next generation"
                title="Schools"
                subtitle="Our future decision makers" />

            <VideoRow
                img={Cosmo}
                imageHeight="200px"
                lineText="Meet Cosmo"
                title="KidsSDGs"
                paragraph1="Meet Cosmo, he's going to take our next generation on a journey to help save our planet."
                video={Sustainability}
                divClass="cosmo"
            />

            <TextImageRow
                lineText="the global challenge"
                title="The next 10 years is crucial for our planet. Let's help our future generation to understand the challenge"
                paragraph1="The challenge to educate our future generation on how they can live more sustainable lives and contribute to saving our planet starts in our schools. We also want to share experiences in the classroom with our global community and encourage a 'ONE World' approach with a real cultural exchange of ideas encouraging our next generation of school children across the globe to adopt ethical practices as they move towards their career choices."
                image={Children}
                divClass=""
                animate={true}
            />

            <TextImageRow
                lineText="You can make A Difference"
                title="Our Aim"
                paragraph1="academyONE and The Sustainability Academy are passionate about giving back and we will be challenging our clients to do the same by sponsoring a child or a school to complete the KidsSDGs programme, either in their local communities or from some of our schools in less developed environments in Africa to complete the KidsSDGs programme."
                image={Boy}
                divClass=""
                animate={true}
                rowReverse={true}
            />

            <TextImageRow
                lineText="With Schools in mind"
                title="The Sustainability Academy's KidsSDGs"
                paragraph1="The Sustainability Academy KidsSDGs Programme is designed to adapt to the needs of pupils across key stages 2 - 4, mapped to the national curriculum and is aligned to the 17 Sustainable Development Goals. It is designed to raise awareness of the key sustainability challenges we all face. "
                image={Classroom}
                divClass=""
                animate={true}
            />

            <TextImageRow
                lineText="Study at your own pace"
                title="A Flexible Study Programme "
                paragraph1="A fully comprehensive enrichment programme encouraging enterprise and entrepreneurship aligned to the SDGs where pupils will study each SDG as a module either online or in the classroom and be assessed at the end of each module.  There is an end-project based around building a business within their community which encompasses and is assessed against all 17 SDGs."
                image={Students}
                divClass=""
                animate={true}
                rowReverse={true}
            />
        </>
    );
};

export default Schools;