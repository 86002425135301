import React, { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import './Layout.scss';
import Footer from '../Footer/Footer';
import MobileMenu from './../MobileMenu/MobileMenu';

const Layout = ({ children }) => {
    const [mobMenu, toggleMobMenu] = useState(false);

    function toggleMenu() {
        toggleMobMenu(!mobMenu);
    }

    return (
        <React.Fragment>
            <MobileMenu mobMenu={mobMenu} toggleMenu={toggleMenu} />
            <Navbar toggleMenu={toggleMenu} mobMenu={mobMenu} />
            <main className="container-fluid px-0 content" style={{ paddingTop: '78px' }}>
                {children}
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Layout;